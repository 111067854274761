/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* @import 'ngx-toastr/toastr-bs5-alert'; */
@import "~@ng-select/ng-select/themes/default.theme.css";
body {
    /* margin-top: 20px; */
}

/* buttons */

.wrapper {
    margin: 0px 20px;
}

.mat-calendar-body-label {
    font-size: 0px !important;
    padding: 0px !important;
}

.menuIcon {
    margin-right: 12px;
}

.btn-primary {
    background: #42478B !important;
    border-radius: 10px !important;
    border: 1px solid #42478b !important;
    min-width: 7.5rem !important;
}

.btn-outline-primary {
    background: #ffffff !important;
    border-radius: 10px !important;
    border: 1px solid #42478b !important;
    color: #42478B !important;
    min-width: 7.5rem !important;
}

.btn-outline-secondary {
    background: #ffffff !important;
    border-radius: 10px !important;
    /* border: 1px solid #42478b !important;
    color: #42478B !important; */
    min-width: 7.5rem !important;
}

.btn-outline-secondary:hover {
    background: #ffffff !important;
    border-radius: 10px !important;
    color: #616161 !important;
    min-width: 7.5rem !important;
}

.icon-pointer {
    cursor: pointer;
}

.view-btn {
    text-align: end;
    text-transform: capitalize;
}

.list-link {
    padding: 5px 15px;
    color: #42478B;
    cursor: pointer;
    text-decoration: underline;
}

.list-link-disabled {
    padding: 5px 15px;
    color: #9e9e9e;
    text-decoration: underline;
}

.text-decor {
    font-weight: 300;
    padding: 5px 15px;
    text-transform: capitalize;
}

.text-heading {
    font-size: 24px;
    font-weight: 300;
    text-transform: capitalize;
}

.text-heading-popup {
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;
}


/* padding */

.p1rem {
    padding: 1rem !important;
}

.p5 {
    padding: 5px !important;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.pt15 {
    padding-top: 15px !important;
}

/* text align */

.text-align-end {
    text-align: end !important;
}

.text-align-center {
    text-align: center !important;
}

.text-align-justify {
    text-align: justify !important;
    font-weight: 300;
}

/* nav - nav-links */

.nav-link.active {
    color: #42478B !important;
    background-color: #ffffff !important;
    border-bottom: 2px solid #42478B !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    margin: 0px 2px;
    font-weight: 600;
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    color: #000000;
    margin: 0px 2px;
    /* border-top-left-radius: var(--bs-nav-tabs-border-radius); */
    /* border-top-right-radius: var(--bs-nav-tabs-border-radius); */
}

.nav-link:hover {
    color: #42478bc1 !important;
    background-color: #ffffff !important;
    border-bottom: 2px solid #42478BC1 !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

/* sessions tab */
.session-header {
    font-weight: 700;
    font-size: 24px;
}

.card {
    margin: 0.5rem 0 !important;
}

.card:hover {
    border: 1px solid #42478bca !important;
    box-shadow: 0.5vh 1.5vh 2.5vh #42478b78 !important;
}

/* cdk */
.cdk-global-overlay-wrapper {
    pointer-events: auto !important;
    overflow-y: scroll !important;
    align-items: start !important;
    z-index: auto !important;
}

.cdk-global-overlay-wrapper:has(.addreqcustomclass) {
    align-items: center !important;
  }

  .cdk-global-overlay-wrapper:has(.verifcustomclass) {
    align-items: center !important;
  }

 
.cdk-overlay-pane {
    margin: 1rem;
    width: 100% !important;
    z-index: 1000;
    margin-top: 2px;
}

.overlay {
    background-color: #8a8a8ad1 !important;
    height: 100%;
    width: 100%;
    z-index: 9999;
    margin: auto;
}

.cdk-overlay-pane {
    max-width: fit-content !important;
    margin: 0px !important;
}

.custom-dialog-container .mat-dialog-container {
    top: 61vh !important;
    right: -61vh !important;
    border-radius: 20px !important;
}

.mat-dialog-container {
    background-color: white;
    margin-bottom: 20px !important;
    border-radius: 5px !important;
    padding: 15px 24px !important;
    position: relative;
    width: 100% !important;
    height: 100% !important;
    top: 1vh !important;
    bottom: 1vh !important;
    /* overflow: hidden !important; */
}

.accordion-button:not(.collapsed) {
    color: #000000 !important;
    font-size: 2.5vh;
    font-weight: 600;
    background-color: #ffffff !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    /* border-bottom: 1px solid #000000; */
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 2.5vh !important;
    font-weight: 600;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

input.star:checked~label.star:before {
    content: "\f005";
    color: #F68C1F !important;
    transition: all .25s;
    font-size: 14px !important;
}

label.star {
    float: right;
    padding: 2px !important;
    font-size: 15px !important;
    color: #F68C1F !important;
    transition: all .2s;
}

.toast-top-center {
    top: 10px !important;
}

#toast-container>div {
    -moz-border-radius: 30px 30px 30px 30px;
    -webkit-border-radius: 30px 30px 30px 30px;
    border-radius: 30px 30px 30px 30px;
    width: fit-content !important;
    padding-right: 30px !important;
}

.toast-success {
    background-color: #05b626;
}

.no-data {
    text-align: center;
    font-weight: 200;
    font-size: 24px;
    border-top: 1px solid #DEDEDE;
    padding-top: 25px;
    text-transform: capitalize;
}

::-webkit-scrollbar {
    height: 0 !important;
    width: 6px !important;
    /* Remove scrollbar space */
    background: transparent !important;
    /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background: #f6f6f6;
}

.loader-animation {
    position: absolute !important;
    z-index: 10000 !important;
    margin-left: 50vw !important;
    /* display: block; */
    margin-top: 50vh !important;
}

.mat-date-range-input .mat-date-range-input-container {
    border: 1px solid rgba(181, 181, 181, 0.3607843137);
    justify-content: space-evenly;
    margin-left: 10px;
}

.mat-date-range-input-start-wrapper {
    position: static !important;
    overflow: hidden !important;
    max-width: 50% !important;
}

.mat-datepicker-toggle .mat-icon-button {
    border-radius: 0% !important;
    height: 0px !important;
    width: 0px !important;
}

.mat-datepicker-toggle .mat-icon-button:focus {
    border-radius: 0% !important;
    background-color: #ffffff;
}



.wrapper_main .slick-prev::before {
    content: url("../src/assets/images/vectorleft.svg");
    margin-left: 64px
}

.wrapper_main .slick-prev {
    left: -18.6vw;
    height: 100%;
    width: 20%;
    background-color: #f6f6f6f6;

}

.wrapper_main .slick-next {
    right: -18.6vw;
    height: 100%;
    width: 20%;
    background-color: #f6f6f6f6;
}

.wrapper_main .slick-next::before {
    content: url("../src/assets/images/vectorright.svg");
    margin-right: 64px
}

.wrapper_main .slick-prev:hover {
    background: #f6f6f6f6;

}

.wrapper_main .slick-next:hover {
    background: #f6f6f6f6;

}

.wrapper_main .slick-prev:focus {
    background: #f6f6f6f6;
}

.wrapper_main .slick-next:focus {
    background: #f6f6f6f6;
}

.wrapper_thumb .slick-track,
.wrapper_main .slick-track {
    margin-left: 0;
    display: flex;
    align-items: center;
}

/* datatable */
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
    height: inherit;
    font-size: 2vh;
    border-bottom: 0.2vh solid #c5c5c5;
    font-weight: 600;
    color: #202020;
    padding: 1vh 0vh 1vh;
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
    overflow-x: hidden;
    vertical-align: top;
    display: inline-block;
    line-height: 1.625;
    font-size: 2vh;
    font-weight: 400;
    padding: 1vh 0vh;
    color: #777;
}

.form-range {
    width: 90% !important;
    height: 1.5rem;
    padding: 2vh !important;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='range']::-webkit-slider-thumb {
    /* width: 6px;
    -webkit-appearance: none;
    height: 12px; */
    background: #42478B;
    /* border-radius: 2px; */
}

.form-check-input:checked {
    background-color: #42478B;
    border-color: #42478B;
}

.form-check-inline {
    display: inline-block;
    margin-right: 7vw;
}

label {
    display: inline-block;
    font-size: 2.2vh;
    text-transform: capitalize !important;
}

.text-bg-secondary {
    color: #42478b !important;
    background-color: #dededebc !important;
    font-size: 1.6vh;
}





#side_menu::-webkit-scrollbar {
    height: 0 !important;
    width: 2px !important;
    background: transparent !important;
}

#side_menu::-webkit-scrollbar-thumb {
    background: #e7e7e7 !important;
    border-radius: 10px !important;
}

#side_menu::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background: #f6f6f6;
}

.reopendialog .mat-button.mat-button-disabled.mat-button-disabled{
    color: #ffffff !important;
   opacity: 0.5;
}